import Vue from 'vue'
import Vuex from 'vuex'
import staticData from '@api/staticData'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        modalNaoPermitido: false,
        logo: null,
        cor: '#F1892C',
        isLogged: false,
        loading: false,
        user: null,
        unreadChats: [],
        listaEstados: [],
        listaBanco: [],
        filtroSuporte: {
            itemsPerPage: 25,
            search: '',
            selectedDate: [],
            statusList: [1],
            usuario_id: ''
        },
        filtroHome: {
            usuario_id: '',
            pendentes: true,
            concluidos: false,
            atrasados: false,
            cliente_id: '',
            selectedDate: [
                new Date(new Date().setDate(new Date().getDate() - 30)),
                new Date(),
            ],
        },
        filtroTarefa: {
            itemsPerPage: 25,
            search: '',
        },
        tituloImpressao: '',
        headersImpressao: [],
        conteudoImpressao: [],
        isFromImpressao: '',
        totalImpressao: '',
    },
    getters: {

    },
    mutations : {
        isLogged(state, payload){
            state.isLogged = payload
        },
        setLoading(state, payload){
            state.loading = payload
        },
        setUser(state, payload){
            state.user = payload
        },
        setUnreadChats(state, payload){
            state.unreadChats = payload
        },
        setListaEstados(state, payload){
            state.listaEstados = payload
        },

        setFiltroHome(state, payload){
            state.filtroHome = payload
        },

        setFiltroTarefa(state, payload){
            state.filtroTarefa = payload
        },

        setFiltroSuporte(state, payload){
            state.filtroSuporte = payload
        },

        setHeadersImpressao(state, payload){
            state.headersImpressao = payload
        },

        setConteudoImpressao(state, payload){
            state.conteudoImpressao = payload
        },

        setTotalImpressao(state, payload){
            state.totalImpressao = payload
        },

        setTituloImpressao(state, payload){
            state.tituloImpressao = payload
        },

        setIsFromImpressao(state, payload){
            state.isFromImpressao = payload
        },
        setLogo(state, payload){
            state.logo = payload
        },
        setCor(state, payload){
            state.cor = payload
        },
        setListaBanco(state, payload) {
            state.listaBanco = payload
        },

    },
    actions: {
        async loadStaticData({commit}){

            const listaStaticData = await staticData.getAll()
            commit('setListaEstados', listaStaticData.data.estados)
            commit('setLogo', listaStaticData.data.empresa.imagem)
            commit('setCor', listaStaticData.data.empresa.cor)
            commit('setListaBanco', listaStaticData.data.banco)

        }
    },
})

export default store
